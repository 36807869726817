<template>
  <RegistoParaEntidade />
</template>

<script>
import RegistoParaEntidade from "@/components/users/RegistoParaEntidade.vue";

export default {
  components: {
    RegistoParaEntidade
  }
};
</script>
